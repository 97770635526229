import clsx from "clsx";
import React from "react";
import { InfoIcon } from "~/components/ui/info-icon";

export type CheckboxProps = JSX.IntrinsicElements["input"] & {
	children?: React.ReactNode;
	checked?: boolean;
	id: string;
	tooltip?: React.ReactNode;
};

export function Checkbox({
	checked = false,
	disabled = false,
	id,
	children,
	className,
	tooltip,
	...props
}: CheckboxProps) {
	return (
		<label
			htmlFor={id}
			className={clsx(
				"group flex w-fit cursor-pointer items-center gap-3",
				{
					// placeholder for disabled state
					disabled: disabled,
				},
				className
			)}
		>
			<input
				type="checkbox"
				className={clsx(
					// base element
					"border-stroke m-0 h-[20px] w-[20px] flex-shrink-0 appearance-none rounded border",
					// tick element
					"after:ml-[6px] after:mt-[2px] after:block after:h-[12px] after:w-[5px] after:rotate-45 after:border-b-[2px] after:border-r-[2px] after:border-white after:p-1 after:opacity-0 after:content-['']",
					// checked state
					"checked:bg-primary-70 checked:after:opacity-100",
					// hover state
					"hover:border-primary-70 group-hover:border-primary-70"
				)}
				checked={checked}
				disabled={disabled}
				id={id}
				{...props}
			/>
			{typeof children !== "undefined" && children}
			{tooltip ? <InfoIcon tooltip={tooltip} /> : null}
		</label>
	);
}
